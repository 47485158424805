body {
  background-color: #000000;
 
}

.skillheader{
  text-align: center;
  color:white;
  font-family: 'Ruda', sans-serif;
}

.contact{
  text-align: center;
  color: antiquewhite;
}

:root {
  --background: white;
  --text-primary: black;
  --text-secondary: rgb(255, 255, 255);
  --accent: purple;
}
[data-theme='dark'] {
  --background: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

