.header-h1 {
    color: antiquewhite;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
}
.navbar {
    background-color: rgb(255, 246, 246);
    border-radius: 15px;
    padding: 10px 20px;
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 10px;
    box-sizing: border-box;
    text-align: center;
}

.navbar a {
    color: rgb(27, 22, 22);
    text-decoration: none;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: black;
    font-size: 17px;
    margin-top: auto;
    margin-bottom:auto;
}

.navbar a:hover {
    background-color: #272223;
    color: rgb(255, 254, 253);
}

@media screen and (max-width: 500px) {
    .navbar {
        flex-direction: column; /* Stack items vertically */
        padding: 10px;
    }

    .navbar a {
        font-size: 15px;
        padding: 8px 12px;
    }
}

@media screen and (max-width: 350px) {
    .navbar {
        padding: 8px;
    }

    .navbar a {
        font-size: 14px;
        padding: 8px;
        text-align: center;
    }
}

.container-banner {
    background-color: #f1f1f1;
    text-align: center;
    border-style: hidden;
    border-radius: 20px;
    font-family: 'Ruda', sans-serif;
}

#profile-pic {
    border-radius: 85px;
    padding-top: 10px;
}

.container-about {
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    border-style: hidden;
    padding: 18px;
    text-align: center;
}

.container-about>p {
    font-family: 'Slabo 27px', serif;
    font-size: 20px;
    padding-right: 30px;
    padding-left: 30px;
}

.container-about>h1 {
    font-family: 'Ruda', sans-serif;
}

.flex-project-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.flex-project-container>div {
    background-color: #f1f1f1;
    width: 150px;
    height: 150px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
    border-style: hidden;
    border-radius: 30px;
}

.flex-project-container img {
    margin-top: 27px;
}

.flex-project-container>div:hover {
    transform: scale(1.1);

}

.projects {
    background-color: rgb(20, 18, 18);
    border-radius: 15px;
    border-style: hidden;
    padding: 18px;
    text-align: center;

}

.projects h1 {
    font-family: 'Ruda', sans-serif;
}

.projects p {
    font-family: 'Slabo 27px', serif;
    font-size: 20px;
}

.container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.container a {
    padding-right: 10px;
    text-decoration: none;

}

.container p {
    color: black;
    font-family: 'Slabo 27px', serif;
}

.container a:hover {
    transform: scale(1.1);
}

.footer {
    color: #f1f1f1;
    text-align: center;
    font-family: 'Saira Semi Condensed', sans-serif;
    font-size: 16px;
    margin-top: auto; 
}

.footer>a {
    padding-left: 5px;
    font-size: 22px;
    text-decoration: none;
}

span:hover {
    text-decoration: none;
    transform: scale(1.5);
}

body {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #333;
}

h1 {
    font-size: 2.2rem;
    font-weight: 800;
}

h3 {
    font-size: 1.4rem;
    font-weight: 500;
    color: #777;
}

p {
    font-size: 1.1rem;
    line-height: 1.7;
    max-width: 1200px;
    margin: auto;
}

span {
    color: #4CAF50;
    font-weight: bold;
}